import ApplicationController from '../application_controller';
import { DateTime } from "luxon";
import ReconnectingWebSocket from "reconnecting-websocket";

export default class extends ApplicationController {
  static targets = [
    "dashboardUpcomingEvents",
    "upcomingEvents",
    "cloudRecordings",
    "dashboardLiveEvents"
  ];

  static values = {
    url: String,
    userId: String,
    env: String,
    view: String
  };

  async initialize() {
    if (!this.baseSocket) {
      const webSocketProtocol =
        this.envValue === "development" ? "ws" : "wss";
      const hubUrl = `${webSocketProtocol}:${this.urlValue}`;
      this.baseSocket = new ReconnectingWebSocket(hubUrl);
      this.baseSocket.onopen = ({ target }) => {
        console.log("[wsserver] Connection established");
        this.baseSocket.send(`subscribe:${this.envValue};user;${this.userIdValue}`);
      };
      this.baseSocket.onmessage = (e) => {
        const payload = JSON.parse(e.data);
        switch (payload.type) {
          case "event:created":
          case "event:updated":
          case "event:destroyed":
          case "event:past":
            console.log(`[wsserver] ${payload.type}: ${JSON.stringify(payload.data)}`);
            this.reloadEventFrame();
            this.reloadDashboardUpcomingEvents();
            break;
          case "broadcast:saved":
          case "recording:created":
          case "recording:transcoded":
          case "recording:updated":
          case "collection:recording_removed":
            console.log(`[wsserver] ${payload.type}: ${JSON.stringify(payload.data)}`);
            this.reloadRecordingsFrame();
            break;
          case "event:upcoming":
            console.log(`[wsserver] ${payload.type}: ${JSON.stringify(payload.data)}`);
            this.updateUpcomingEvent(payload.data.event);
            break;
          case "recording:destroyed":
            console.log(`[wsserver] ${payload.type}: ${payload.data}`);
            this.reloadRecordingsFrame(true);
            break;
          case "broadcast:start":
            console.log(`[wsserver] ${payload.type}: ${payload.data}`);
            this.dispatch('broadcastStarted');
            this.reloadLiveEventsFrame();
            break;
          case 'finished':
          case 'event:stopped':
            console.log(`[hub] ${payload.type}: ${JSON.stringify(payload.data)}`);
            this.reloadLiveEventsFrame(true);
            break;
          default:
            console.log(`[wsserver] Unknown message type: ${payload.type}`);
        }
      };
      this.baseSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log(
            `[wsserver] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          console.log("[wsserver] Connection died");
        }
      };
    }
  }

  updateUpcomingEvent(event) {
    const eventToUpdate = document.getElementById(`event_${event.id}`);
    if (eventToUpdate) {
      const startsAt = DateTime.fromISO(event.starts_at);
      const endsAt = DateTime.fromISO(event.ends_at);
      if (startsAt <= DateTime.now() && endsAt > DateTime.now()) {
        eventToUpdate.classList.add('bg-mixlr-blue-light');
        const nowButton = `<div class="flex-none w-[60px] text-center">
          <span class="bg-mixlr-blue text-xs font-bold inline-block text-white rounded-full px-2 py-1">Now</span>
        </div>`;
        eventToUpdate.querySelector(':scope > a').insertAdjacentHTML('beforeend', nowButton);
      }
    } else {
      this.reloadDashboardUpcomingEvents();
    }
  }

  reloadDashboardUpcomingEvents() {
    if (this.hasDashboardUpcomingEventsTarget) {
      this.dashboardUpcomingEventsTarget.reload();
    }
  }

  reloadEventFrame() {
    if (this.hasUpcomingEventsTarget) {
      this.upcomingEventsTarget.setAttribute("src", "");
      this.upcomingEventsTarget.setAttribute(
        "src",
        this.upcomingEventsTarget.dataset.src
      );
    }
  }

  reloadRecordingsFrame(deleting = false) {
    // If deleting on a show view, no need to reload the frame
    if (deleting && this.viewValue) {
      if (this.viewValue === 'recordings_show') return;
    }
    if (this.hasCloudRecordingsTarget) {
      this.cloudRecordingsTarget.setAttribute("src", "");
      this.cloudRecordingsTarget.setAttribute(
        "src",
        this.cloudRecordingsTarget.dataset.src
      );
    }
  }

  reloadLiveEventsFrame(emptyFrame = false) {
    if (this.hasDashboardLiveEventsTarget) {
      if (emptyFrame) {
        this.dashboardLiveEventsTarget.innerHTML = "";
      } else {
        this.dashboardLiveEventsTarget.reload();
      }
    }
  }
}
