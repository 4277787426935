import ApplicationController from './application_controller';
import { Icon } from '../vendor/icons';

export default class extends ApplicationController {
  static targets = [
    'navList',
    'actionButtonLink',
    'actionButtonText',
    'actionButtonCreatorLinks',
    'actionButtonCreatorSpace',
    'login',
    'demo',
    'listenLink'
  ]
  static values = {
    header: Boolean,
    creatorsUrl: String,
    creatorDownloadUrl: String,
    creatorDeepLinkUpsellUrl: String,
    creatorAppLaunchUrl: String,
    creatorNewEventUrl: String,
    creatorNewRecordingUrl: String,
    editAccountUrl: String,
    enterpriseAccount: Boolean,
    signoutUrl: String,
    priceplansUrl: String,
    broadcastsUrl: String
  }

  connect() {
    if (typeof currentuserData === 'undefined') return;

    if (this.headerValue) {
      this.replaceNav();
    }
  }

  replaceNav() {
    this.loginTarget.remove();
    if (this.enterpriseAccountValue) {
      this.actionButtonLinkTarget.href = this.creatorsUrlValue;
      this.actionButtonTextTarget.textContent = 'Enterprise';
      this.demoTarget.remove();
      this.actionButtonCreatorLinksTarget.remove();
      this.actionButtonCreatorSpaceTarget.remove();
    } else if (currentuserData.is_premium) {
      this.actionButtonLinkTarget.href = this.creatorsUrlValue;
      this.actionButtonTextTarget.textContent = 'Creators';
      this.listenLinkTarget.href = this.broadcastsUrlValue;
      const creatorNavigation = `
          <li>
            <a href="${this.creatorsUrlValue}" class="action-button__link">
              ${currentuserData.channel_logo_url ? `
                <div class="image image--thumb-mini flex-none">
                  <img src="${currentuserData.channel_logo_url}" alt="" class="image__img">
                </div>
              ` : ''}
              <span class="font-bold truncate">${currentuserData.channel.name}</span>
            </a>
          </li>
          <li>
            <a href="${currentuserData.plan.reference === 'lite' || !currentuserData.live_streaming_enabled ? this.creatorDeepLinkUpsellUrlValue : this.creatorAppLaunchUrlValue}" class="action-button__link">${Icon('', false, 'live_now', 'icon--mini')} Go live now</a>
          </li>
          ${currentuserData.live_streaming_enabled ? `
            <li>
              <a href="${this.creatorNewEventUrlValue}" class="action-button__link">${Icon('', false, 'calendar', 'icon--mini')} Schedule event</a>
            </li>
          ` : ''}
          ${currentuserData.can_upload_recordings ? `
            <li>
              <a href="${this.creatorNewRecordingUrlValue}" class="action-button__link">${Icon('', false, 'publish', 'icon--mini')} Upload recording</a>
            </li>
          ` : ''}
        `;
      this.actionButtonCreatorLinksTarget.innerHTML = creatorNavigation;
      this.demoTarget.remove();
    }

    const signedInNavigation = `
      <li class="action-button group">
        <div class="image image--thumb">
          <img src="${currentuserData.profile_image_url}" alt="" class="image__img">
        </div>
        <div class="action-button__dropdown group-hover:visible mt-1">
          <ul class="action-button__list">
            <li>
              <a href="${this.editAccountUrlValue}" class="action-button__link">${Icon('', false, 'user', 'icon--mini')} My account</a>
            </li>
            <li>
              <a href="${this.signoutUrlValue}" class="action-button__link">${Icon('', false, 'lock', 'icon--mini')} Logout</a>
            </li>
          </ul>
        </div>
      </li>
    `;
    this.navListTarget.insertAdjacentHTML('beforeend', signedInNavigation);
  }
}
