import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'time',
    'date'
  ]
  static values = {
    timezone: String
  }

  connect() {
    this.setTime();
    this.startTimer();
  }

  disconnect() {
    this.stopTimer();
  }

  setTime() {
    const timeString = new Date().toLocaleTimeString([], { timeStyle: 'short', hourCycle: 'h12' });
    const dateString = new Date().toLocaleDateString([], { dateStyle: 'medium' });

    this.element.title = `${timeString} • ${dateString}`;
    this.element.setAttribute('datetime', new Date());

    if (this.hasTimeTarget) {
      this.timeTarget.textContent = timeString;
    }
    if (this.hasDateTarget) {
      this.dateTarget.textContent = dateString;
    }
  }

  startTimer() {
    this.localTimer = setInterval(() => {
      this.setTime();
    }, 1000);
  }

  stopTimer() {
    if (this.localTimer) {
      clearInterval(this.localTimer);
    }
  }
}