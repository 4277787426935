import ApplicationController from './application_controller';
import Typed from 'typed.js';

export default class extends ApplicationController {
  static targets = []
  static values = {}

  connect() {
    const typed = new Typed(this.element, {
      strings: ['Sports', 'Podcasting', 'Faith', 'Music'],
      typeSpeed: 100,
      startDelay: 200,
      backSpeed: 0,
      fadeOut: true,
      loop: true,
      autoInsertCss: false
    });
  }
}
