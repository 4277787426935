import ApplicationController from './application_controller';
import Globe from 'globe.gl';

export default class extends ApplicationController {
  static targets = []
  static values = {
    globeImageUrl: String
  }

  connect() {
    const ARC_REL_LEN = 0.4; // relative to whole arc
    const FLIGHT_TIME = 1000;
    const NUM_RINGS = 3;
    const RINGS_MAX_R = 5; // deg
    const RING_PROPAGATION_SPEED = 5; // deg/sec
    const N = 20;
    const arcsData = [...Array(N).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 180,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 180,
      endLng: (Math.random() - 0.5) * 360,
      color: [['red', 'red', 'red', 'red'][Math.round(Math.random() * 3)], ['red', 'red', 'red', 'red'][Math.round(Math.random() * 3)]]
    }));

    if (!this.globeImageUrlValue) return;
    this.rotatingGlobe = Globe();
    this.rotatingGlobe(this.element)
      .globeImageUrl(this.globeImageUrlValue)
      .backgroundColor('white')
      .arcsData(arcsData)
      .arcColor('color')
      .arcDashLength(() => Math.random())
      .arcDashGap(() => Math.random())
      .arcDashAnimateTime(() => Math.random() * 4000 + 500)
      .atmosphereColor(`rgba(237,28,36,1)`)
      .ringColor(() => t => `rgba(237,28,36,${1 - t})`)
      .ringMaxRadius(RINGS_MAX_R)
      .ringPropagationSpeed(RING_PROPAGATION_SPEED)
      .ringRepeatPeriod(FLIGHT_TIME * ARC_REL_LEN / NUM_RINGS)
      .enablePointerInteraction(false);

    this.rotatingGlobe.controls().autoRotate = true;
    this.rotatingGlobe.controls().enableZoom = false;
    this.rotatingGlobe.width([this.element.parentElement.clientWidth])
    this.rotatingGlobe.height([this.element.parentElement.clientHeight])
  }

  resize(event) {
    this.rotatingGlobe.width([this.element.parentElement.clientWidth])
    this.rotatingGlobe.height([this.element.parentElement.clientHeight])
  }
}