import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'count',
    'svg'
  ]
  
  static values = {
    visible: Boolean
  }

  connect() {
    if (typeof Intercom === 'undefined') return;
    const count = this.countTarget;
    const svg = this.svgTarget;
    Intercom('update', {
      'hide_default_launcher': true
    });
    Intercom('onUnreadCountChange', function(unreadCount) {
      count.textContent = unreadCount > 99 ? '99' : unreadCount
      if(unreadCount === 0) {
        svg.style.display = 'block';
        count.style.display = 'none';
      } else {
        svg.style.display = 'none';
        count.style.display = 'inline-flex';
      }
    });
  }

  show() {
    Intercom('show');
  }
}